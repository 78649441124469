@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    height: 95vh;
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
}

.title {

    font-size: 100px;
}

@media only screen and (max-width: 768px) {
    .landing-page {
        text-align: center;
    }
    .title {
        font-size: 80px;
    }
}