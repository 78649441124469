.about-page {
    /* background-color: #333; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* @media only screen and (max-width: 768px) {
    .about-page {
        text-align: center;
    }
} */