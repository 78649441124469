@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

.dark-mode {
    background-color: black !important;
    color: white !important;
}

html {
    scroll-behavior: smooth;
  }